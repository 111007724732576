<template>
  <div>
    <el-select @change="scopeChange" style="width: 100%" v-model="scopeShow" multiple placeholder="请选择">
      <el-option v-for="item in scopeOption" :key="item.id + ''" :label="item.title" :value="item.id + ''"></el-option>
    </el-select>
  </div>
</template>

<script>
export default {
  name: "StoreChoose",
  model:{
    prop:"modelVal",
    event:"change"
  },
  props:{
    modelVal:{
      type:String,
      default:"",
    }
  },
  watch:{
    modelVal(){
      this.value = this.modelVal;
      if(this.modelVal)this.scopeShow = this.modelVal.split(",");
    },
    value(){
      this.$emit("change",this.value);
    },
  },
  data(){
    return{
      value:this.modelVal,
      scopeShow:[],
      scopeOption:[],
    }
  },
  mounted() {
    this.$u.api.shop.plugin.scanGoods.storeAll().then(res=>{
      this.scopeOption = res;
      if(this.modelVal)this.scopeShow = this.modelVal.split(",");
    })
  },
  methods:{
    scopeChange(val){
      this.value = val.join(",");
    }
  }
}
</script>

<style scoped>

</style>