<template>
  <el-card style="min-height: 45rem">
    <el-table :data="list">
      <el-table-column prop="id" label="编号" show-overflow-tooltip></el-table-column>
      <el-table-column prop="title" label="名称" show-overflow-tooltip></el-table-column>
      <el-table-column prop="fee" label="价格" show-overflow-tooltip></el-table-column>
      <el-table-column width="140" label="专题">
        <template #default="s">
          <el-tag size="mini" v-for="(item,key) in s.row.theme_titles" :key="key">{{ item }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="升级商品规则" width="100" show-overflow-tooltip>
        <template v-slot="s">{{s.row.mode_upgrade ? '已加入' : '未加入'}}</template>
      </el-table-column>
      <el-table-column label="可核销门店" width="100">
        <template v-slot="s">
          <div><el-tag v-for="(item,key) in s.row.cashier_store" :key="key">{{item}}</el-tag></div>
        </template>
      </el-table-column>
      <el-table-column prop="stock" label="库存" show-overflow-tooltip></el-table-column>
      <el-table-column prop="sales" label="销量" show-overflow-tooltip></el-table-column>
      <el-table-column prop="total_use" label="可核销次数" show-overflow-tooltip></el-table-column>
      <el-table-column prop="use_limit" label="间隔" show-overflow-tooltip></el-table-column>
      <el-table-column prop="time_start" label="开始日期" show-overflow-tooltip></el-table-column>
      <el-table-column prop="time_end" label="截止日期" show-overflow-tooltip></el-table-column>
      <el-table-column label="操作" width="200" fixed="right">
        <template slot="header">
          <el-button @click="editBox=true" type="primary">添加商品</el-button>
        </template>
        <template v-slot="s">
          <el-button @click="edit(s.row)" type="primary">编辑</el-button>
          <el-button @click="del(s.row)" type="danger">删除</el-button>
        </template>
      </el-table-column>
      <template #append>
        <el-pagination style="padding: .5rem" background layout="total,prev, pager,next"
                       :total="total" :page-size="15" :current-page="page" @current-change="pageChange">
        </el-pagination>
      </template>
    </el-table>
    <el-dialog title="编辑商品" destroy-on-close :visible.sync="editBox" @close="editClose">
      <div class="flex-def flex-zCenter">
        <el-form :model="editForm" label-width="5rem" style="width: 40rem">
          <el-form-item label="名称">
            <el-row>
              <el-col :span="12"><el-input v-model="editForm.title"></el-input></el-col>
              <el-col :span="12">
                <div class="flex-def flex-zEnd flex-cCenter">
                  <div style="margin-right: .5rem">加入系统升级商品规则</div>
                  <el-switch v-model="editForm.mode_upgrade"></el-switch>
                </div>
              </el-col>
            </el-row>
          </el-form-item>
          <el-form-item label="图标">
            <el-row>
              <el-col :span="editForm.mode_upgrade ? 10 : 24">
                <y_upload_img style="margin-right: 1rem" v-model="editForm.pic"></y_upload_img>
              </el-col>
              <el-col v-if="editForm.mode_upgrade" :span="14">
                <div class="flex-def">
                  <div style="white-space: nowrap">首页缩略图</div>
                  <y_upload_img v-model="editForm.home_pic"></y_upload_img>
                </div>
              </el-col>
            </el-row>

          </el-form-item>
          <el-form-item label="专题">
            <el-select @change="themeChange" style="width: 100%" v-model="theme_val" multiple filterable allow-create default-first-option placeholder="请选择专题标签">
              <el-option v-for="item in theme_opt" :key="item.id" :label="item.title" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="价格">
            <div class="flex-def flex-zBetween">
              <div><el-input-number v-model="editForm.fee"></el-input-number>元</div>
              <div>原价<el-input-number v-model="editForm.o_fee"></el-input-number>元</div>
              <div>库存<el-input-number v-model="editForm.stock"></el-input-number></div>
            </div>
          </el-form-item>
          <el-form-item label="直推奖励">
            <div class="flex-def flex-zBetween">
              <div><el-input-number v-model="editForm.award_invite_fee"></el-input-number>元</div>
              <div>间推<el-input-number v-model="editForm.award_invite2_fee"></el-input-number>元</div>
              <div style="width: 10rem"></div>
            </div>
          </el-form-item>
          <el-form-item label="核销次数">
            <div class="flex-def flex-zBetween">
              <div><el-input-number v-model="editForm.total_use"></el-input-number></div>
              <div><el-input-number v-model="editForm.use_limit"></el-input-number>天(两次核销间隔期限)</div>
              <div></div>
            </div>
          </el-form-item>
          <el-form-item label="使用门栏">
            <el-input v-model="editForm.tips"></el-input>
          </el-form-item>
          <el-form-item label="使用期限">
            <el-date-picker
                v-model="range"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="核销门店">
            <StoreChoose v-model="editForm.store_ids"></StoreChoose>
          </el-form-item>
          <el-form-item label="详情介绍">
            <y_editor :eid="'edit' + editForm.id" v-model="editForm.detail" type="textarea"></y_editor>
          </el-form-item>
          <el-form-item align="right">
            <el-button @click="editSubmit" type="primary">保存</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>

  </el-card>
</template>

<script>
import y_upload_img from "@/components/y-form/y-upload/y_upload_img";
import y_editor from "@/components/y-form/y-editor/y_editor";
import StoreChoose from "@/components/shop/plugin/scanGoods/StoreChoose";
export default {
  components:{StoreChoose, y_upload_img,y_editor},
  name: "manage",
  data(){
    return{
      list:[],
      page:1,
      total:0,
      editBox:false,
      range:[],
      editForm:{
        pic:"",
        title:"",
        fee:0,
        o_fee:0,
        stock:0,
        detail:"",
        time_start:"",
        time_end:"",
        total_use:0,
        use_limit:0,
        tips:"",

        store_ids:"",
        home_pic:"",
        award_invite_fee:0,
        award_invite2_fee:0,
        mode_upgrade:false,
      },
      theme_val:[],
      theme_opt:[],
      themeLoading:false,
    }
  },
  mounted() {
    this.load();
    this.loadThemeOpt();
  },
  methods:{
    loadThemeOpt(){
      this.$u.api.shop.goods.themeSearch({page:-1}).then(res=>{
        this.theme_opt = res.list;
      })
    },
    themeChange(val){
      val.forEach((item,key)=>{
        if (typeof item === 'string'){
          this.themeLoading = true;
          this.$u.api.shop.goods.themeEdit({title:item}).then(id=>{
            val[key] = id
            this.themeLoading = false;
            this.loadThemeOpt();
          })
        }
      })
    },
    del({id}){
      this.$u.api.shop.plugin.scanGoods.goodsV2Del({id}).then(()=>{
        this.$message.success("操作成功");
        this.load();
      })
    },
    async edit(item){
      this.theme_val = await this.$u.api.shop.plugin.scanGoods.goodsV2id2themeId({id:item.id})
      this.editForm = {...item};
      this.range = [
        item.time_start,
        item.time_end,
      ]
      this.editBox = true;
    },
    editClose(){
      this.theme_val = [];
      this.editForm = {
        pic:"",
        title:"",
        fee:0,
        o_fee:0,
        stock:0,
        detail:"",
        time_start:"",
        time_end:"",
        total_use:0,
        use_limit:0,
        tips:"",

        store_ids:"",
        home_pic:"",
        award_invite_fee:0,
        award_invite2_fee:0,
        mode_upgrade:false,
      }
      this.range = [];
    },
    editSubmit(){
      this.editForm.time_start = this.range[0];
      this.editForm.time_end = this.range[1];
      this.$u.api.shop.plugin.scanGoods.goodsV2Edit({goods:this.editForm,theme_ids:this.theme_val}).then(()=>{
        this.$message.success("操作成功");
        this.editBox = false;
        this.load();
      })
    },
    pageChange(e){
      this.page = e;
      this.load();
    },
    load(){
      this.$u.api.shop.plugin.scanGoods.goodsV2Search({page:this.page}).then((res)=>{
        this.list = res.list;
        this.total = res.total;
      })
    },
  },
}
</script>

<style scoped>

</style>